/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


var menu = function () {
    var appmenu = null;

    /**
     * Para tratamento de eventos...
     * */
    var eventHandler = function () {
        try {
            /**
             * Abrir e fechar menu
             */
            $(".header-menu", appmenu).click(function () {
                if ($(".header-menu", appmenu).hasClass("active")) {
                    $(".header-menu", appmenu).removeClass("active");
                    $(".container-menu .menu", appmenu).removeClass("active");

                } else {
                    $(".header-menu", appmenu).addClass("active");
                    $(".container-menu .menu", appmenu).addClass("active");
                }
            });
            // abrir e fecha pesquisa 
            $(".header-option .option-search").click(function () {
                if ($(".container-search").hasClass("active")) {
                    $(".container-search").removeClass("active");
                } else {
                    $(".container-search").addClass("active");
                }
            });
            /*
             * Tratamento dos subMenu parte dos produtos
             */
            $(".menu-products a.highlight.parent",appmenu).click(function (e){
                    if($(window).width() <= 1023){
                        e.stopPropagation();
                        e.preventDefault();
                        var parent = $(this).parent();
                        if(parent.hasClass("on"))
                        {
                            parent.removeClass("on");
                        }else
                        {
                            parent.addClass("on");
                        }
                   }
              });
               /*
             * Tratamento dos subMenu parte dos produtos
             */
            $("a.highlight.parent",appmenu).click(function (e){
                    if($(window).width() <= 1023){
                        e.stopPropagation();
                        e.preventDefault();
                        var parent = $(this).parent();
                        if(parent.hasClass("on"))
                        {
                            parent.removeClass("on");
                        }else
                        {
                            parent.addClass("on");
                        }
                   }
              });
               /*
             * Tratamento dos subMenu geral
             */
            $(".menu-products a.highlight.parent",appmenu).click(function (e){
                    if($(window).width() <= 1023){
                        e.stopPropagation();
                        e.preventDefault();
                        var parent = $(this).parent();
                        if(parent.hasClass("on"))
                        {
                            parent.removeClass("on");
                        }else
                        {
                            parent.addClass("on");
                        }
                   }
              });
            // Evento do botão fechar da pesquisa
            $(".content-search .close").click(function () {
                $(".container.container-search").removeClass("active");
            });
            // Evento que mostra as linguas
            $(".header-option ul li").hover(function (){
               $(".header-option ul li").css("display","flex"); 
            });
            // Evento que esconde as linguas 
             $(".header-option ul li ").mouseleave(function (){
                    $(".header-option ul li:not(.active)").css("display","none"); 
                   
               });
//            $(".header-option ul li.active ").off("click");
            $(".header-option ul li.active a ").click(function (evt) {
                        evt.preventDefault();
            });
            
               
               
               
                

        } catch (e) {
            console.log('[MENU:]: ' + e);
        }
    };
    return {
        init: function (_appmenu) {
            appmenu = _appmenu;
            eventHandler();
        }
    };
}();

menu.init();